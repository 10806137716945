<template>
  <mf-loading-dialog :loading="loading">
    <v-container class="pb-2 mt-n4" fluid>
      <v-layout>
        <p class="ml-1 page-subtitle">Cadastrar Downsell</p>
        <v-spacer />
      </v-layout>
      <v-card-actions class="px-0 mt-2 mb-1">
        <v-btn outlined width="300" color="error" @click="$router.push(`/retailers/${clientId}/edit/acoes`)">Cancelar</v-btn>
        <v-spacer />
      </v-card-actions>
      <v-card class="pt-4">
        <v-card-title class="mb-6">
          <v-text-field
            v-model="search"
            class="pt-0 mt-0"
            width="300"
            hide-details
            single-line=""
            placeholder="Busca (nome do produto)"
            append-icon="search"
            outlined
          />
          <v-spacer />
          <v-btn color="error" outlined class="mr-5" width="160" :disabled="!selectedProducts.length" @click="selectedProducts = []"
            ><v-icon class="mr-1">mdi-trash-can-outline</v-icon> Limpar</v-btn
          >
          <v-btn color="primary" :disabled="!selectedProducts.length" @click="openDownsell"
            ><v-icon class="mr-1" small>mdi-cog</v-icon> Configurar downsell</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selectedProducts"
            :loading="$apollo.loading"
            :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
            :server-items-length="maxRecords"
            :options.sync="options"
            :headers="headers"
            :items="activeProductsData"
            :search="search"
            item-key="_id"
            show-select
          >
            <template v-slot:item="{ item, isSelected, select }">
              <tr class="clickable-row" @click="select(!isSelected)">
                <td>
                  <v-checkbox class="mt-0" :input-value="isSelected" hide-details @click.stop="select(!isSelected)"></v-checkbox>
                </td>
                <td>{{ item._id }}</td>
                <td>{{ getProductFromType(item.product_type).name }}</td>
                <td>
                  <v-icon class="mr-2" x-small :color="item.active ? 'success' : 'error'">mdi-brightness-1</v-icon>{{ item.active ? 'Ativo' : 'Inativo' }}
                </td>
                <td>{{ parseCurrencyDefault(item.total_mrr) }}</td>
                <td>{{ item.slots | localeString(0) }}</td>
                <td>{{ formatDate(item.created_at) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <downsell-dialog v-if="downsellDialog" :dialog.sync="downsellDialog" :client-id="clientId" :products="selectedProducts" @click="registerAction" />
    <dialog-confirm-sale v-model="confirmSale" is-downsell :validate-contract-link="false" :disable-button="disableButton" @confirm-action="saveAction" />
  </mf-loading-dialog>
</template>

<script>
import { QUERY_GET_AVAILABLE_PRODUCTS, MUTATION_INSERT_SALE } from '@/modules/retailers/graphql'
import { parseCurrency, productsInformation } from '@/mixin'

export default {
  components: {
    DownsellDialog: () => import('@/components/atomic-components/molecules/DownsellDialog.vue'),
    DialogConfirmSale: () => import('@/components/atomic-components/molecules/DialogConfirmSale')
  },
  mixins: [parseCurrency, productsInformation],
  props: {
    clientId: {
      type: String,
      default: () => ''
    },
    salesType: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    loading: false,
    search: '',
    options: {},
    maxRecords: 0,
    activeProductsData: [],
    selectedProducts: [],
    downsellDialog: false,
    dataAction: {},
    disableButton: false,
    confirmSale: false
  }),
  computed: {
    isCreate() {
      return this.$route.meta.title === 'Downsell'
    },
    headers() {
      return [
        {
          text: 'ID Produto',
          value: '_id'
        },
        {
          text: 'Produto',
          value: 'product_type'
        },
        {
          text: 'Status',
          value: 'active'
        },
        {
          text: 'Total MRR',
          value: 'total_mrr'
        },
        {
          text: 'Quantidade adquirida',
          value: 'slots'
        },
        {
          text: 'Data de criação',
          value: 'created_at'
        }
      ]
    }
  },
  apollo: {
    availableProducts: {
      query: QUERY_GET_AVAILABLE_PRODUCTS,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { client_id: this.$route.params.id },
          { filters: { active: true } },
          {
            pagination: {
              page: this.options.page || 1,
              pageSize: this.options.itemsPerPage || 10,
              search: this.search || null,
              sort: this.options.sortBy || ['_id'],
              sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
            }
          }
        )
      },
      update({ availableProducts: { count, data } }) {
        this.maxRecords = count
        this.activeProductsData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  methods: {
    formatDate(date) {
      return this.$moment(date)
        .add(3, 'h')
        .format('DD/MM/YYYY - HH:mm:ss')
    },
    registerAction(data) {
      this.dataAction = data
      this.downsellDialog = false
      this.confirmSale = true
    },
    async saveAction({ date, contract_link, ambev_incentive, observation, cause, reason }) {
      this.disableButton = true
      const variables = {
        client_id: this.clientId,
        sale: {
          type: this.dataAction.sale.type,
          items: this.dataAction.sale.items,
          created_at: date,
          contract_link,
          observation,
          cause,
          reason
        }
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_INSERT_SALE,
          variables,
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })
        this.$alert({
          alert_message: 'Downsell realizado com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$router.push(`/retailers/${this.clientId}/edit/acoes`)
        this.disableButton = false
      } catch (error) {
        console.log(error)
        this.confirmSale = false
        this.disableButton = false
        this.$alert({
          alert_message: 'Falha ao realizar downsell venda',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    openDownsell() {
      this.downsellDialog = true
    }
  }
}
</script>

<style lang="scss">
.v-data-table .clickable-row {
  cursor: pointer;
}
</style>
